@import "../../../../assets/styles/global-style/styles.scss";
.opensea-nft-validator-connected-right {
  min-height: 110px;

  .ev-generic-button-container {
    width: 80%;
    img {
      width: $font-medium-size;
      height: $font-medium-size;
    }
  }
}
