@import "../setup/mixins";

* {
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  /** 
  * Most web browsers have a base font-size of 16px.
  * This defines a base font size for using rem units.
  * Formula => (11px / 16px) * 100 = 68.75% 
  */
  font-size: 68.75%;
  font-family: "Primary", sans-serif;
  color: $font-primary-color;
  direction: ltr;
  letter-spacing: 0.08rem;
}
// @include for-mobile-layout {
//   html {
//     font-size: 81.25%;
//   }
// }
// @include for-tablet-layout {
//   html {
//     font-size: 81.25%;
//   }
// }
@include for-desktop-layout {
  html {
    font-size: 75%;
  }
}
// @include for-big-desktop-layout {
//   html {
//     font-size: 81.25%;
//   }
// }
// @include for-huge-desktop-layout {
//   html {
//     font-size: 90.625%;
//   }
// }

body {
  margin: 0;
}

p,
h1,
h2,
h3,
h3,
h5,
h6 {
  margin: 0;
  display: inline-block;
}
ul {
  list-style: none;
  padding-left: 0;
}
button {
  cursor: pointer;
  font-family: inherit;
  color: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}
