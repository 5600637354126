@import "../../../../index.scss";

.invalid-wallet-errors-not-whitelisted-container {
  .sacrifice-countdown-container {
    [class^="ev-generic-text"] {
      font-size: $font-small-size;
    }
    .countdown-display-show-counter {
      [class^="ev-generic-text"] {
        font-size: $font-large-size !important;
      }
    }
  }

  .sacrifice-countdown-container-placeholder {
    margin-bottom: 0.5rem;

    [class^="ev-generic-text"] {
      font-size: $font-small-size !important;
      line-height: $font-medium-size !important;
    }
  }
}
