@import '../../assets/styles/global-style/setup/variables';

.back-button-container {
  position: absolute;
  bottom: $spacing-large;
  right: $spacing-large;
  z-index: 1000;
  pointer-events: auto;
  .clickable-text {
    color: #fff;
  }
}
