@import "../../../../assets/styles/global-style/styles.scss";

.select-nft-grid-container {
  width: 80%;
  height: 100%;
  padding: 2rem 4rem;
  overflow: scroll;

  .select-nft-grid {
    width: 100%;
    min-height: 100%;
    height: fit-content;
    padding-bottom: $spacing-large;
    @include grid(3, $spacing-small);
  }
}
