@import "../../../../assets/styles/global-style/setup/variables";

$icon-size: 3rem;

#mute-audio-icon {
  position: absolute;
  top: 3.2rem;
  right: 16rem;

  width: $icon-size;
  height: $icon-size;

  z-index: $z-index-ev-logo;

  &:hover {
    cursor: pointer;
  }
}
