//COLORS

//base
$app-color-black: #000000;
$app-color-white: #ffffff;

//main-colors
$pale-peach: #f7e0b2;
$beige: #f9f4ea;
$sand-20: #e8c37833;
$sand-10: #e8c3781a;
$brown-grey: #979797;
$pumpkin-90: #de9200e6;
$sand-80: #e8c37a69;
$white-80: #ffffffcc;

//font-color
$font-primary-color: $app-color-white;
$font-secondary-color: rgba(93, 206, 198, 1);
$font-disabled-color: #5ecfc79a;

//border-color
$selected-border-color: rgba(93, 206, 198, 0.69);

//backgrounds
$header-color: $app-color-white;
$footer-color: $app-color-white;

//bg-color
//warning-color

//title-color

//input-color

//link-color

//PADDING
$padding-small: 2.67rem;
$padding-medium: 2rem;
$padding-large: 2.3rem 4rem;

//SPACING
$spacing-x-small: 0.8rem;
$spacing-small: 1rem;
$spacing-medium: 2rem;
$spacing-large: 3.25rem;

//SIZES
$header-h: 5rem;
$footer-mobile-h: 12.5rem;
$footer-desktop-h: 6rem;

$font-huge-size: 4.2rem;
$font-x-large-size: 2.7rem;
$font-large-size: 2.5rem;
$font-medium-size: 2.2rem;
$font-small-size: 1.6rem;
$font-x-small-size: 1.4rem;

//FONT WEIGHT
$font-weight-regular: 400;
$font-weight-bold: 700;

// LAYOUT BREAKPOINTS
$break-huge-screen: 2029px;
$break-big-screen: 1925px;
$break-desktop: 1445px;
$break-tablet: 920px;
$break-mobile: 480px;

//BTNS

//GRADIANTS
$bg-grad-down: linear-gradient(180deg, #101010cf 45%, rgba(17, 17, 17, 0) 100%);
$bg-grad-up: linear-gradient(0, #101010cf 45%, rgba(17, 17, 17, 0) 100%);
$bg-grad-rtl: linear-gradient(
  -90deg,
  #000000 -25.51%,
  rgba(0, 0, 0, 0) 101.69%
);
$bg-grad-ltr: linear-gradient(90deg, #000000 -25.51%, rgba(0, 0, 0, 0) 101.69%);
$bg-grad-ltr-hover: linear-gradient(
  90deg,
  #2929298a -25.51%,
  rgba(58, 58, 58, 0) 101.69%
);
$bg-grad-bright: linear-gradient(
  179.1deg,
  rgba(255, 255, 255, 0.347) -53.24%,
  rgba(255, 255, 255, 0.105) 99.22%
);

//BLUR
$bg-blur: blur(90.8609px);
$bg-blur-soft: blur(50px);

//SHADOWS
$shadow-small: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$nft-card-shadow: 0px 4.22609px 49.8928px rgba(218, 218, 218, 0.28);

// Z-Indexes
$z-index-background-renderer: 10;
$z-index-foreground-renderer: 200;
$z-index-ev-logo: 3000;
$z-index-popup: 40000;
