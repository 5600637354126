@import '../../../../assets/styles/global-style/setup/variables';

.foreground-renderer-container {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: $z-index-foreground-renderer;
  background-color: rgba(255, 255, 255, 0);

  .account-address {
    position: absolute;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 40px;
    left: 40px;
  }
}
