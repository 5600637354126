@import "../setup/variables";
/* helpers */

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-item-grow-1 {
  flex-grow: 1;
}

.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.column-reverse {
  flex-direction: column-reverse;
}
.column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.row {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.self-start {
  align-self: flex-start;
}

.hide {
  display: none;
}

// TEXT

.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-family: "Primary-Bold";
}

.title-large {
  font-size: $font-large-size;
  font-family: "Primary-Bold";
  letter-spacing: 0.2rem;
  text-align: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}
.errors {
  color: red;
}

// SPACING

.l-mb {
  margin-bottom: $spacing-large;
}
.m-mb {
  margin-bottom: $spacing-medium;
}
.s-mb {
  margin-bottom: $spacing-small;
}
.xs-mb {
  margin-bottom: $spacing-x-small;
}
.l-mt {
  margin-top: $spacing-large;
}
.m-mt {
  margin-top: $spacing-medium;
}
.s-mt {
  margin-top: $spacing-small;
}
.xs-mt {
  margin-top: $spacing-x-small;
}
.l-mr {
  margin-right: $spacing-large;
}
.m-mr {
  margin-right: $spacing-medium;
}
.s-mr {
  margin-right: $spacing-small;
}
.xs-mr {
  margin-right: $spacing-x-small;
}
.l-ml {
  margin: $spacing-large;
}
.m-ml {
  margin-left: $spacing-medium;
}
.s-ml {
  margin-left: $spacing-small;
}
.xs-ml {
  margin-left: $spacing-x-small;
}
.l-m {
  margin: $spacing-large;
}
.m-m {
  margin: $spacing-medium;
}
.s-m {
  margin: $spacing-small;
}
.xs-m {
  margin: $spacing-x-small;
}

.l-pad {
  padding: $padding-large;
}
.m-pad {
  padding: $padding-medium;
}
.s-pad {
  padding: $padding-small;
}

// SIZES

.w-all {
  width: 100%;
}

.h-all {
  height: 100%;
}

.w-content {
  width: fit-content;
}

// FONT

.weight-regular {
  font-weight: $font-weight-regular;
}
.weight-bold {
  font-weight: $font-weight-bold;
}

.underline {
  text-decoration: underline;
}

.s-line-h {
  line-height: $font-small-size;
}
.m-line-h {
  line-height: $font-medium-size;
}
.l-line-h {
  line-height: $font-large-size;
}

// CUSTOM COMPONENTS

.clickable-text {
  color: $font-secondary-color;
  &:hover {
    cursor: pointer;
    color: $font-primary-color;
    text-decoration: underline;
  }
}

// BACKGROUNDS

.bg-grad-down {
  background: $bg-grad-down;
}
.bg-grad-up {
  background: $bg-grad-up;
}
.bg-grad-rtl {
  background: $bg-grad-rtl;
}
.bg-grad-ltr {
  background: $bg-grad-ltr;
}

.bg-blur {
  backdrop-filter: $bg-blur;
}

// BORDERS

.active-link-bottom-border {
  border-bottom: 0.2rem solid $pumpkin-90;
}

.round-corners-narrow {
  border-radius: 8px;
}
.round-corners-wide {
  border-radius: 12.5px;
}
