@import "../../../../assets/styles/global-style/setup/mixins";
@import "../../../../assets/styles/global-style/setup/variables";

$bribe-img-size: 13rem;
$eth-img-size: $font-small-size;

.mint-card-container {
  img {
    width: calc(#{$bribe-img-size} * 1.06);
    height: $bribe-img-size;
  }

  .ev-generic-button-container {
    min-width: 20rem;
  }

  .cta-wrapper {
    position: relative;

    .invalid-text {
      position: absolute;
      left: 50%;
      bottom: -25px;
      transform: translateX(-50%);
      width: 150%;
      text-align: center;
    }
  }

  .bribe-mint-card-footer {
    img {
      border: none;
      width: $eth-img-size;
      height: $eth-img-size;
    }
  }
}
