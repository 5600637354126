.sacrifice-section-content-ctas-container- {
  &after {
    max-width: 53.5rem;
    width: 87%;
  }
  &before {
    max-width: 49.5rem;
    width: 76%;
  }
}
