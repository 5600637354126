@import "../../../../assets/styles/global-style/styles.scss";
.select-nft-item {
  .nft-card-container {
    &:hover {
      cursor: pointer;
      scale: 102%;
      box-shadow: $shadow-small;
      backdrop-filter: $bg-blur;
    }
  }

  &-selected {
    .nft-card-container {
      border: 0.175rem solid $selected-border-color;
    }
  }
}
