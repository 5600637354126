.select-nft-container {
  width: 100%;
  height: 100%;

  .select-nft-inner-container {
    height: 52rem;
    width: 73rem;
    overflow: hidden;
  }
}
