@import "../../../../assets/styles/global-style/styles.scss";

.sacrifice-section-content-container {
  width: 90%;
  height: 20%;
  margin-top: auto;

  .sacrifice-section-content-inner-container {
    height: 85%;
    width: 80%;

    .sacrifice-section-content-wrapper {
      width: 100%;
      max-width: 60rem;
    }

    .sacrifice-section-content-header-container {
      margin-bottom: $spacing-medium;
    }
    .sacrifice-section-content-footer-container {
      margin-top: $spacing-medium;
    }
  }
}
