.sacrifice-section-swap-display-container {
  height: 65%;

  .sacrifice-section-items-boundries {
    position: relative;
    height: 75%;
    max-height: 45rem;
    min-height: 38rem;
    width: 45%;
    max-width: 60rem;
    min-width: 49rem;

    // [class^="sacrifice-section-nft-wrapper"] {
    //   width: fit-content;
    //   height: fit-content;

    //   position: absolute;
    // }

    // .sacrifice-section-nft-wrapper {
    //   &-back {
    //     scale: 0.6;
    //     opacity: 0.5;
    //   }

    //   &-front {
    //     left: 50%;
    //     bottom: 0%;
    //     transform: translate(-50%, 0%);
    //     scale: 1.15;
    //   }

    //   &-final {
    //     left: 55%;
    //     bottom: 0%;
    //     transform: translate(-55%, 0%);
    //     scale: 1.3;
    //   }
    // }

    .float-item {
      width: fit-content;
      height: fit-content;
      position: absolute;

      &.ev {
        bottom: 70%;
        left: 0;
      }
      &.nft {
        bottom: 0;
        left: 50%;
        transform: translateX("-50%");

        scale: 1.15;
      }
    }
  }
}
