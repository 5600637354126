@import "../../assets/styles/global-style/setup/variables";
.btn- {
  &light {
    background: $font-secondary-color;
    color: black;
    border: none;
    &:hover {
      background: rgb(255, 255, 255);
    }
    &-disabled {
      background: $font-disabled-color;
      color: black;
      &:hover {
        cursor: default;
      }
    }
  }
  &dark {
    font-family: "Primary";
    color: white;
    background: $app-color-black;
    border: 1px solid white;
    &-disabled {
      color: $font-secondary-color;
      background: $app-color-black;
      border: 1px solid $font-secondary-color;
      &:hover {
        cursor: default;
      }
    }
    &:hover {
      background: rgba(66, 66, 66, 0.516);
    }
  }
}
.ev-generic-button-container {
  border-radius: 2px;
  padding: 12px;
  font-family: "Primary";
  min-width: 19.25rem;
}
