@import "../../../../../assets/styles/global-style/setup/variables";

#WEB3_CONNECT_MODAL_ID {
  position: absolute;
  z-index: $z-index-popup;

  .web3modal-modal-lightbox {
    backdrop-filter: blur(90.8609px) !important;

    .web3modal-modal-card {
      background: none !important;

      .web3modal-provider-wrapper {
        border: none !important;
        &:hover {
          cursor: default;
        }

        .web3modal-provider-container {
          background: $bg-grad-ltr !important;

          &:hover {
            cursor: pointer;
            background: $bg-grad-ltr-hover !important;
          }
        }
      }
    }
  }
}
