[class^="text-bubble-container-"] {
  z-index: 5;

  position: absolute;

  width: fit-content;
  pointer-events: auto;
  background-color: rgb(0, 0, 0, 0.6);
  box-shadow: rgba(85, 85, 85, 0.25);
}

.text-bubble-container- {
  &left {
    border-radius: 13px 13px 13px 3px;
  }
  &right {
    border-radius: 13px 13px 3px 13px;
  }
}
