@import "./assets/styles/global-style/setup/variables";

.App {
  width: 100vw;
  height: 100vh;
}

.popup-container {
  width: 100vw;
  height: 100vh;

  position: absolute;
  z-index: $z-index-popup;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  backdrop-filter: $bg-blur;
}
