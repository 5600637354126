@import "../../assets/styles/global-style/styles.scss";
.opensea-nft-validator-left-container {
  width: fit-content;
  max-width: 55vw;
  p {
    line-height: 2rem;
  }
  h1,
  h2,
  h3 {
    line-height: 2.75rem;
  }

  .nft-card-container {
    margin-bottom: $spacing-large;
  }
}
