@import "../../assets/styles/global-style/setup/variables";

.bribe-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: +10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.bribe-background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
}
