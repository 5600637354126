@import "../../../../assets/styles/global-style/setup/variables";

$z-index-hidden-video: calc(1 + #{$z-index-background-renderer});
$z-index-display-video: calc(5 + #{$z-index-background-renderer});

[class^="background-renderer-container-"] {
  position: fixed;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: $z-index-background-renderer;
  background-color: black;

  [id^="video-"] {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }

  #video- {
    &hidden {
      z-index: $z-index-hidden-video;
    }
    &display {
      z-index: $z-index-display-video;
    }
  }
}

.background-renderer-container- {
  &web {
    height: 100%;
    width: 100%;
  }
  &mobile {
    top: 48%;
    height: 125%;
    width: 125%;
  }
}
