@import "../../assets/styles/global-style/setup/variables";

$logo-height: 5rem;

#eternal-vikings-logo {
  position: absolute;
  width: calc(2.13 * #{$logo-height});
  height: $logo-height;
  top: 2.2rem;
  right: 4rem;
  z-index: $z-index-ev-logo;
  &:hover {
    cursor: pointer;
  }
}
