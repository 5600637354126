.fullscreen-grad-frame- {
  &top {
    top: 0;
  }
  &bottom {
    bottom: 0;
  }
}

[class^="fullscreen-grad-frame-"] {
  width: 100%;
  height: 50%;
  position: absolute;
  z-index: -1;
}
