@import "../../../../assets/styles/global-style/styles.scss";

.opensea-nft-validator-input-container {
  min-width: 41rem;

  input {
    color: white;
    font-family: "Primary";
    font-size: $font-small-size;
    background: rgba(0, 0, 0, 0);
    padding: 1rem;
    border: 1px solid white;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    line-height: $font-huge-size;
  }

  input:hover {
    cursor: pointer;
    background: rgba(66, 66, 66, 0.516);
  }
}
