@import "../../../../assets/styles/global-style/styles.scss";

.faq-container {
  position: absolute;
  backdrop-filter: blur(85px);
  bottom: clamp(2.5rem, 2.75rem, 3rem);
  right: clamp(2.5rem, 2.75rem, 3rem);
  overflow: hidden;
  height: fit-content;
  padding: 1.7rem 4rem;
  p,
  h3 {
    line-height: 1.8rem;
  }
  #close-faq:hover {
    cursor: pointer;
  }
}

@include for-short-screens {
  .faq-container {
    overflow: auto;
    max-height: calc(100vh / 1.26);
  }
}
