$container-height: 27.9rem;

.welcome-section-how-it-works {
  &-container {
    height: $container-height;
  }
  &-header {
    height: calc(#{$container-height} * 0.05);
  }
  &-content {
    height: calc(#{$container-height} * 0.93);
  }
}
