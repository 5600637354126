@import "../../../../assets/styles/global-style/setup/variables";

$guard-size: 50rem;

.guard-container {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  .guard-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: $guard-size;
      height: calc(#{$guard-size} * 1.1);
    }
  }
}
